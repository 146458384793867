import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { closeToastMessage, setToastMessage } from '../../../../core/actions/core.action.creators';

import {
  createGroupInvoices,
  fetchingGeneratedInvoices,
  getBatchRecords,
  prepareRecords,
  saveBatchRecord,
  saveClaimRecord,
  updateTeleplanInvoice
} from '../../../actions/claims.action.creators';
import { timeDiff } from '../../../../utils/formatTime';
import { inputs } from '../helpers/inputs';
import { dataForSubmit, formatPatientsListForFirstVisit, recordsForSubmit } from '../helpers/dataForSubmit';
import { trimLeftZeros } from '../../../../utils/trimLeftZeros';
import { updateRecord } from '../helpers/updateRecord';
import { generateRecord } from '../helpers/generateRecord';
import { steps } from '../helpers/steps';
import { isSupplementary } from '../../../../config/specialitiesConfig';
import { createPatient, editPatient } from '../../../../patients/actions/patients.action.creators';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { calculateUnits } from '../helpers/calculateUnits';
import { useStorage } from '../../../../hooks/useStorage';
import { showMultipleServiceDate } from '../helpers/inputsConditions';
import { concat, flatten, groupBy, isArray, isEmpty, map, reject, sortBy, uniqBy } from 'lodash';
import { bonusFeeCodes } from '../../../../config/defaultValuesConfig';
import { sendErrorReport } from '../../../../../service/ClinicService';
import { validateBeforSubmit } from '../helpers/validationSchema';
import { t } from '../../../../../service/localization/i18n';
import { v4 as uuidv4 } from 'uuid';
import { codesThatRequiresBMI } from '../helpers/codesCheckList';
import { formatBMI } from '../helpers/formatBMI';
import { decodeText } from '../../../../utils/decodeText';
import moment from 'moment';
import { resetBatchFormFromLocalStorage, setBatchFormToLocalStorage } from '../helpers/formToLocalStorage';

export const useSubmit = ({ watch, setValue, reset, localState, setLocalState, declinedStatus, isNew, isGroup, errors }) => {
  const key = localStorageKeys.doNotShowEmergencyServiceDialog;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { teleplanInvoice } = useSelector((state) => state.claims);
  const [doNotShowToday] = useStorage(key);

  const firstVisit = location.pathname.indexOf('first-visit') > -1;

  const onSubmit = (invoice) => {
    const isFormValid = validateBeforSubmit({ invoice, localState, setLocalState, isNew, isGroup, errors, doNotShowToday });
    if (!isFormValid) return;

    invoice[inputs.ignoreValidation.name] && delete invoice[inputs.ignoreValidation.name];

    // Gor to group picker step
    if (isGroup) return onNext();

    // Create new
    if (isNew) return generateInvoices(invoice);

    // Edit invoice
    // if (!isNew) return onSubminExistingInvoice(invoice);
    if (!isNew) return onReview(invoice);
  };

  const generateInvoices = async (invoice) => {
    // CMO-2798 - 1st visit -> time is carried over from previous patient
    dispatch(closeToastMessage(true));

    // Format submit data
    const formatedInvoice = dataForSubmit({ invoice, isNew, firstVisit });

    // If no fee codes
    if (!formatedInvoice[inputs.feeCodes.name]?.length) {
      // Save as draft
      if (invoice[inputs.saveAsDraft.name]) return onSaveNewInvoiceAsDraft(invoice, []);
      return;
    }

    // Update form state for step 2, 3, 4
    firstVisit && setValue(inputs.patient.name, formatPatientsListForFirstVisit(formatedInvoice));

    const showMultipleDates = localState.isMultipleDates;
    const serviceDates = invoice[inputs.serviceDate.name];

    if (showMultipleDates && isArray(serviceDates)) {
      const butchRequests = invoice?.[inputs.serviceDate.name].map(async (i) => {
        return await dispatch(prepareRecords({ ...formatedInvoice, [inputs.serviceDate.name]: moment(i).format('YYYY-MM-DD') }));
      });

      dispatch(fetchingGeneratedInvoices(true));
      const batchRecords = await Promise.all(butchRequests);
      dispatch(fetchingGeneratedInvoices(false));

      generateInvoicesResponseAction(invoice, batchRecords.flat());
    } else {
      dispatch(getBatchRecords(formatedInvoice, (responseRecords) => generateInvoicesResponseAction(invoice, responseRecords)));
    }
  };

  const generateInvoicesResponseAction = (invoice, responseRecords) => {
    const formatedInvoice = dataForSubmit({ invoice, isNew, firstVisit });
    const saveAsDraft = formatedInvoice[inputs.saveAsDraft.name];

    // CMO-1513 - When user selects FeeCode 01169 we shall display input box for user to enter BMI
    const updatedRecords = prepareBatchRecords(responseRecords, invoice);
    setLocalState((prevState) => ({ ...prevState, records: updatedRecords }));

    // Save as draft
    if (saveAsDraft) return onSaveNewInvoiceAsDraft(formatedInvoice, updatedRecords);

    // Proceed to next step (NOT as draft)
    if (!saveAsDraft) {
      if (updatedRecords && Array.isArray(updatedRecords) && !updatedRecords.length) {
        // [KS] CMO-1202 - Create new claim - BE return empty claimList for some fee codes
        const _message = String.format(t('Code_is_supplementary'), formatedInvoice[inputs.feeCodes.name]?.replaceAll(',', ', '));
        dispatch(setToastMessage({ type: 'warn', message: _message, lifeTime: 10000 }));

        const errInfo = {
          DCNGuid: formatedInvoice.DCNGuid,
          Route: location.pathname,
          Details: JSON.stringify(formatedInvoice),
          ResponseCode: 400
        };
        sendErrorReport(errInfo);
        return;
      }

      if (updatedRecords.length > 0) {
        // [KS] CMO-1499 - Hide Fee Items catalog for acu (44)
        const speciality = formatedInvoice[inputs.speciality.name];
        const invoiceType = formatedInvoice[inputs.payor.name];
        const step = isSupplementary(speciality, invoiceType) ? steps.preview : steps.records;
        setLocalState((prevState) => ({ ...prevState, step }));
      }
    }
  };

  // For batch view
  const onNext = () => {
    const startTime = watch(inputs.startTime.name);
    const endTime = watch(inputs.endTime.name);
    const isTimeOnTimePicker = localState.groupRecords?.some((i) => {
      return (i[inputs.startTime.name] && i[inputs.startTime.name] !== startTime) || (i[inputs.endTime.name] && i[inputs.endTime.name] !== endTime);
    });

    // CMO-2499 - Group Teleplan -> Conflicts between group and individual times
    if (localState.prevStep === steps.groupServicesPicker && isTimeOnTimePicker && (startTime || endTime)) {
      return setLocalState((prevState) => ({ ...prevState, conflictsBetweenGroupAndIndividualTimesDialog: true }));
    }

    return proceedToGroupServices();
  };

  // For batch view
  const proceedToGroupServices = () => {
    const startTime = watch(inputs.startTime.name);
    const endTime = watch(inputs.endTime.name);
    const feeCodes = watch(inputs.feeCodes.codeType);
    const speciality = watch(inputs.speciality.name);
    const invoiceType = watch(inputs.payor.name);
    const step = isSupplementary(speciality, invoiceType) ? steps.groupDxPicker : steps.groupServicesPicker; // CMO-2741 - Teleplan screen behavior changes for supplemental benefit practitioners

    // Check if any of the fee codes are bonus codes
    const isBonusCode = feeCodes.find((i) => bonusFeeCodes.includes(i.value));

    const groupRecords = localState.groupRecords;

    // Prepare the updated group records with necessary modifications
    const updatedGroupRecords = groupRecords.reduce((result, i) => {
      const updatedStartTime = startTime || i[inputs.startTime.name];
      const updatedEndTime = startTime && !endTime ? '' : endTime || i[inputs.endTime.name];
      const duration = startTime && endTime ? timeDiff(startTime, endTime) : i[inputs.duration.name];
      const currentUnits = i[inputs.units.name] || 1;
      const units = startTime && endTime ? calculateUnits(i[inputs.feeCodes.codeType][0], startTime, endTime, currentUnits) : currentUnits;
      const patientArray = Array.isArray(i[inputs.patient.name]) ? i[inputs.patient.name] : [i[inputs.patient.name]];
      const comment = i[inputs.comment.name] || watch(inputs.comment.name);
      const firstStepData = watch();

      const updatedItem = {
        ...watch(), // Copy all data that filled on the first step

        // Update all values that could be changed on the next steps
        [inputs.groupRowId.name]: uuidv4(),
        [inputs.patient.name]: patientArray,
        [inputs.startTime.name]: updatedStartTime,
        [inputs.endTime.name]: updatedEndTime,
        [inputs.duration.name]: duration,
        [inputs.units.name]: units,
        [inputs.comment.name]: comment,
        [inputs.submission.name]: i[inputs.submission.name],
        [inputs.refToBy.name]: 'N',
        [inputs.isDuplicated.name]: i[inputs.isDuplicated.name] || false,

        [inputs.feeCodes.name]: i[inputs.feeCodes.name] || [],
        [inputs.feeCodes.codeDescription]: i[inputs.feeCodes.codeDescription] || [],
        [inputs.feeCodes.codeType]: i[inputs.feeCodes.codeType] || [],

        [inputs.icd9.name]: i[inputs.icd9.name] || [],
        [inputs.icd9.codeDescription]: i[inputs.icd9.codeDescription] || [],
        [inputs.icd9.codeType]: i[inputs.icd9.codeType] || [],

        // VER-242 - Batch -> forward -> backwards -> referrals gets messed up
        [inputs.refToBy.name]: i[inputs.refToBy.name] || 'N',
        [inputs.referral.name]: i[inputs.referral.name] || [],
        [inputs.referral.codeDescription]: i[inputs.referral.codeDescription] || [],
        [inputs.referral.codeType]: i[inputs.referral.codeType] || []
      };

      result.push(updatedItem);

      return result;
    }, []);

    // Get all rows with bonus fee codes from the group records
    const rowsWithBonusFeeCodes = updatedGroupRecords.filter((x) => bonusFeeCodes.includes(x[inputs.feeCodes.codeType][0]?.value));
    // Get bonus fee codes only for first patient
    const uniqRowsWithBonusFeeCodes = uniqBy(rowsWithBonusFeeCodes, (record) => record[inputs.feeCodes.codeType][0]?.value);
    const sortedUniqRowsWithBonusFeeCodes = sortBy(uniqRowsWithBonusFeeCodes, (record) => record[inputs.feeCodes.codeType][0]?.value);

    // Remove all items with bonus fee codes from the group records list
    const updatedGroupRecordsWithoutDupes = reject(updatedGroupRecords, (record) =>
      rowsWithBonusFeeCodes.some(
        (suspectRecord) =>
          `${suspectRecord[inputs.patient.name][0]?.PatientGuid}_${suspectRecord[inputs.feeCodes.codeType][0]?.value}` ===
          `${record[inputs.patient.name][0]?.PatientGuid}_${record[inputs.feeCodes.codeType][0]?.value}`
      )
    );

    // Consolidate the updatedGroupRecords and sortedUniqRowsWithBonusFeeCodes arrays to form the final list.
    // The consolidatedGroupRecords will have unique bonus fee codes for the first patient,
    // and all other items without duplicates.
    const consolidatedGroupRecords = concat(sortedUniqRowsWithBonusFeeCodes, updatedGroupRecordsWithoutDupes);

    // Group records by the patient's unique identifier (PatientGuid).
    const groupedItems = groupBy(consolidatedGroupRecords, (item) => item[inputs.patient.name][0].PatientGuid);

    // Get an array of groups (groupedByPatient) containing items with the same PatientGuid.
    const groupedByPatient = flatten(Object.values(groupedItems));

    setLocalState((prevState) => {
      const updatedLocalState = {
        ...prevState,
        step,
        catalogIndex: 0,
        focusFieldParams: {},
        groupRecords: groupedByPatient,
        groupRecordsRequiredInputs: {},
        applyToAllTime: !!startTime && !!endTime && !isBonusCode // CMO-2499 - Group Teleplan -> Conflicts between group and individual times
      };

      // Save the updated form state to the local storage
      setBatchFormToLocalStorage(watch(), updatedLocalState);

      return updatedLocalState;
    });
  };

  const onCreateNewInvoice = () => {
    //sendNow - onSubmit = true, onSave = false
    setValue(inputs.sendNow.name, false);
    // Format submit data
    const formatedInvoice = dataForSubmit({ invoice: watch(), isNew, firstVisit });

    // Create basic new invoice (NOT first visit)
    if (!firstVisit) return createNewInvoice(formatedInvoice);

    // Create new invoice for First visit
    if (firstVisit) return createNewInvoiceForFirstVisit(formatedInvoice);
  };

  const onSubmitNewInvoice = () => {
    //sendNow - onSubmit = true, onSave = false
    setValue(inputs.sendNow.name, true);

    // Format submit data
    const formatedInvoice = dataForSubmit({ invoice: watch(), isNew, firstVisit });

    // Create basic new invoice (NOT first visit)
    if (!firstVisit) return submitNewInvoice(formatedInvoice);

    // Create new invoice for First visit
    if (firstVisit) return createNewInvoiceForFirstVisit(formatedInvoice);
  };

  // const onSubminExistingInvoice = (invoice, closeNow = false) => {
  //   // Format submit data
  //   const formatedInvoice = dataForSubmit({ invoice, isNew, firstVisit });

  //   // Update records for step 3
  //   const updatedRecords = [updateRecord(invoice)];

  //   // on "Review" button click
  //   if (!localState.saveAndClose && !closeNow) {
  //     //  Go to step 3 and NOT save invoice
  //     if (!localState.saveNow) {
  //       return setLocalState(prevState => ({ ...prevState, step: steps.preview, records: updatedRecords }))
  //     };

  //     // Save invoice and go to step 3
  //     if (localState.saveNow) {
  //       return dispatch(saveClaimRecord(formatedInvoice, () => {
  //         // Go to step 3
  //         setLocalState(prevState => ({ ...prevState, step: steps.preview, records: updatedRecords }));
  //         // reset the isDirty value and keep form state
  //         reset({}, { keepValues: true });
  //       }))
  //     };

  //     return;
  //   };

  //   // on "Save" button click
  //   if (localState.saveAndClose || closeNow) {
  //     return dispatch(saveClaimRecord(formatedInvoice, (responseData) => {
  //       // Show warn dialogs (BatchRecordsWarnDialogForOnePatient.js or BatchRecordsWarnDialogForMultiplePatients.js) that patien missing required data
  //       if (responseData?.error) return (responceErrorHandler(responseData));

  //       // Edit Archived, Declined, Not Paid claims
  //       if (declinedStatus) {
  //         // const message = t("You_have_created_a_new_outstanding_claim_by_making_changes_to_a_declined_claim");
  //         // dispatch(setToastMessage({ type: "success", message, lifeTime: 20000 }));
  //         // checkAppliedFilters(filters).length > 0
  //         //   && dispatch(setToastMessage({ type: "success", message: declinedClaimWithFiltersIsEditedSuccessMessage, lifeTime: 20000 }));

  //         // CMO-2446 - Add routing page after Edit declined claim->success
  //         // show Success page
  //         setLocalState(prevState => ({ ...prevState, step: steps.success, createdRecordsList: [responseData.data] }));
  //         // reset the isDirty value and keep form state
  //         reset({}, { keepValues: true });
  //         return;
  //       };

  //       dispatch(setToastMessage({ type: "success", message: saveNewClaimSuccessMessage }));
  //       reset();
  //       history.goBack();
  //     }));
  //   };
  // };

  const onReview = (invoice) => {
    if (localState.saveAndClose) {
      // Format submit data
      const formatedInvoice = {
        ...invoice,
        [inputs.sendNow.name]: false //sendNow - onSubmit = true, onSave = false
      };
      onSubminExistingInvoice(formatedInvoice);
    } else {
      const updatedRecords = [updateRecord(invoice)];
      return setLocalState((prevState) => ({ ...prevState, step: steps.preview, records: updatedRecords }));
    }
  };

  const onSubminExistingInvoice = (invoice) => {
    // Format submit data
    const formatedInvoice = dataForSubmit({ invoice, isNew, firstVisit });

    return dispatch(
      saveClaimRecord(formatedInvoice, (responseData) => {
        // Show warn dialogs (BatchRecordsWarnDialogForOnePatient.js or BatchRecordsWarnDialogForMultiplePatients.js) that patien missing required data
        if (responseData?.error) return responceErrorHandler(responseData);

        // show Success page
        setLocalState((prevState) => ({ ...prevState, step: steps.success, createdRecordsList: [responseData.data] }));
        // reset the isDirty value and keep form state
        reset({}, { keepValues: true });
        return;
      })
    );
  };

  const onCreateGroupInvoices = (sendNow) => {
    // Add additional data from records
    const dataWithAdditionalInfo = localState.groupRecords.map((i) => {
      const feeAmount =
        Array.isArray(i[inputs.feeCodes.codeType]) && i[inputs.feeCodes.codeType]?.length
          ? i[inputs.feeCodes.codeType][0]?.amount
          : i[inputs.feeCodes.codeType]?.amount || 0;

      return {
        ...i,
        [inputs.feeAmount.name]: feeAmount,
        [inputs.sendNow.name]: sendNow
      };
    });

    // Format submit data
    const formatedInvoices = dataWithAdditionalInfo.map((i) => dataForSubmit({ invoice: i, isNew }));
    setValue(inputs.sendNow.name, sendNow);

    dispatch(
      createGroupInvoices(formatedInvoices, (invoicesListResponce) => {
        // show Success page
        setLocalState((prevState) => ({ ...prevState, createdRecordsList: invoicesListResponce, step: steps.success }));
        // Reset the form state from the local storage
        resetBatchFormFromLocalStorage();
        // reset the isDirty value and keep form state
        reset({}, { keepValues: true });
      })
    );
  };

  const getNoteForRecord = (record, invoice) => {
    // CMO-1513 - When user selects FeeCode 01169 we shall display input box for user to enter BMI
    if (codesThatRequiresBMI.includes(record.fee_code)) return `${t('BMI')} ${formatBMI(invoice[inputs.bmi.name])}`;
    // CMO-2267 - Add special handling for fee code 01080
    if (record.fee_code === '01080') return invoice[inputs.reasonFor.name]?.join().replaceAll(',', ', ');

    return decodeText(record.note) || invoice[inputs.comment.name];
  };

  const onSaveNewInvoiceAsDraft = (invoice, recordsList) => {
    // First Visit
    if (firstVisit) {
      const formatedInvoice = dataForSubmit({ invoice: watch(), isNew, firstVisit });
      createNewInvoiceForFirstVisit(formatedInvoice);
    }

    // NOT First Visit
    if (!firstVisit) {
      dispatch(
        saveBatchRecord(invoice, recordsList, (saveBatchRecordResponse) => {
          saveInvoiceNotFirstVist(saveBatchRecordResponse);
        })
      );
    }
  };

  const saveInvoiceNotFirstVist = (saveBatchRecordResponse) => {
    // CMO-1842 - Check if the claim is a duplicate
    if (saveBatchRecordResponse.recordsList?.some((i) => i.Warning)) {
      const warnMsg = t('You_already_have_a_claim_with_the_same_fee_code_on_the_same_date_of_service');
      const officeNumbersList = saveBatchRecordResponse.recordsList?.filter((i) => i.Warning)?.map((i) => trimLeftZeros(i[inputs.recordNumber.name]));
      const officeNumbers = officeNumbersList?.join().replaceAll(',', ', ');
      dispatch(setToastMessage({ type: 'warn', message: `${warnMsg}: ${officeNumbers}`, lifeTime: 40000 }));
    }

    // reset the isDirty value and keep form state
    reset({}, { keepValues: true });

    if (declinedStatus) {
      history.goBack();
    } else {
      // show Success page
      setLocalState((prevState) => ({ ...prevState, step: steps.success }));
    }
  };

  const submitNewInvoice = (invoice) => {
    const formattedRecords = recordsForSubmit(localState.records);

    dispatch(
      saveBatchRecord(invoice, formattedRecords, (responseData) => {
        // Show warn dialogs (BatchRecordsWarnDialogForOnePatient.js or BatchRecordsWarnDialogForMultiplePatients.js) that patien missing required data

        if (responseData?.error) return responceErrorHandler(responseData);

        // show Success page
        setLocalState((prevState) => ({ ...prevState, step: steps.success, createdRecordsList: responseData.recordsList }));
        // reset the isDirty value and keep form state
        reset({}, { keepValues: true });
      })
    );
  };

  const createNewInvoice = (invoice) => {
    const formattedRecords = recordsForSubmit(localState.records);

    dispatch(
      saveBatchRecord(invoice, formattedRecords, (responseData) => {
        const records = responseData.recordsList;

        // CMO-1842 - Check if the claim is a duplicate
        if (records?.some((i) => i.Warning)) {
          const warnMsg = t('You_already_have_a_claim_with_the_same_fee_code_on_the_same_date_of_service');
          const officeNumbersList = records?.filter((i) => i.Warning)?.map((i) => trimLeftZeros(i.RecordNo));
          const officeNumbers = officeNumbersList?.join().replaceAll(',', ', ');
          dispatch(setToastMessage({ type: 'warn', message: `${warnMsg}: ${officeNumbers}`, lifeTime: 40000 }));
        }

        if (declinedStatus) {
          reset();
          history.goBack();
          return;
        }

        // show Success page
        setLocalState((prevState) => ({ ...prevState, step: steps.success, createdRecordsList: records }));
        // reset the isDirty value and keep form state
        reset({}, { keepValues: true });
      })
    );
  };

  const createNewInvoiceForFirstVisit = (formatedInvoice) => {
    const formatedInvoiceForFirstVisit = {
      ...formatedInvoice,
      [inputs.patient.name]: formatPatientsListForFirstVisit(formatedInvoice)
    };

    const dcnguid = formatedInvoiceForFirstVisit[inputs.dcnguid.name]?.toUpperCase();
    const patient = formatedInvoiceForFirstVisit[inputs.patient.name][0];
    const patientGuid = patient?.PatientGuid?.toUpperCase();

    // create new patient
    if (patientGuid === dcnguid || !patientGuid) {
      dispatch(createPatient(patient, (responseData) => createPatientCallback(responseData)));
    }

    // edit patient
    if (patientGuid && patientGuid !== dcnguid) {
      dispatch(editPatient(patient, (responseData) => createPatientCallback(responseData)));
    }
  };

  const createPatientCallback = (responseData) => {
    const updatedClaimRecord = {
      ...watch(),
      [inputs.patient.name]: [responseData]
    };

    const dataForCreate = dataForSubmit({ invoice: updatedClaimRecord, isNew, firstVisit });
    setValue(inputs.patient.name, updatedClaimRecord[inputs.patient.name]);

    //sendNow - onSubmit = true, onSave (onCreate) = false
    updatedClaimRecord.sendNow ? submitNewInvoice(dataForCreate) : createNewInvoice(dataForCreate);
  };

  const responceErrorHandler = (responseData) => {
    setLocalState((prevState) => ({ ...prevState, claimData: responseData }));
    if (responseData?.Issues?.length) {
      const isSingleIssue = responseData?.Issues[0]?.SingleIssue;
      if (isSingleIssue) {
        // reset the isDirty value and keep form state
        reset({}, { keepValues: true });
        setLocalState((prevState) => ({ ...prevState, showBatchRecordsWarnForOnePatient: true }));
        return;
      }

      return setLocalState((prevState) => ({ ...prevState, showBatchRecordsWarnForMultiplePatient: true }));
    }
  };

  const prepareBatchRecords = (records, invoice) => {
    return records.map((i) => {
      const start = i.service_start !== null && i.service_start !== undefined && i.service_start !== -1 ? i.service_start.toString() : '';
      const finish = i.service_finish !== null && i.service_finish !== undefined && i.service_finish !== -1 ? i.service_finish.toString() : '';
      const formattedStart = reformatTime(start);
      const formattedFinish = reformatTime(finish);
      const note = getNoteForRecord(i, invoice);

      return {
        ...i,
        service_start: formattedStart,
        service_finish: formattedFinish,
        note
      };
    });
  };

  const reformatTime = (time) => {
    if (!time) return '';

    const stime = time.toString().padStart(4, '0');
    const hours = stime.slice(0, 2);
    const minutes = stime.slice(2);
    return `${hours}${minutes}`;
  };

  const onClose = () => {
    !isEmpty(teleplanInvoice) && dispatch(updateTeleplanInvoice({}));
    history.goBack();
  };

  return {
    onSubmit,
    onCreateNewInvoice,
    onCreateGroupInvoices,
    onSubmitNewInvoice,
    onSubminExistingInvoice,
    onClose,
    proceedToGroupServices
  };
};
