import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidateGoupPicker } from '../hooks/useValidateGroupPicker';
import { isSupplementary } from '../../../../../../config/specialitiesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';
import { steps } from '../../../helpers/steps';
import { setBatchFormToLocalStorage } from '../../../helpers/formToLocalStorage';

const GroupCommentsActionButtons = () => {
  const history = useHistory();
  const { watch, getValues, localState, setLocalState } = useFormContext();
  const { isCommentValid } = useValidateGoupPicker();

  const onBack = () => {
    const speciality = watch(inputs.speciality.name);
    const invoiceType = watch(inputs.payor.name);
    const step = isSupplementary(speciality, invoiceType) ? steps.groupDxPicker : steps.groupReferralPicker; // CMO-2741 - Teleplan screen behavior changes for supplemental benefit practitioners

    setLocalState((prevState) => ({ ...prevState, step }));
  };

  const onNext = () => {
    // Go to preview group step
    if (isCommentValid()) {
      setLocalState((prevState) => {
        const updatedLocalState = { ...prevState, step: steps.groupPreview, prevStep: prevState.step };
        // Save the updated form state to the local storage
        setBatchFormToLocalStorage(getValues(), updatedLocalState);
        return updatedLocalState;
      });
    }
  };

  return (
    <div className="flex gap-3">
      <Button
        id={elementIDs.next}
        type="button"
        className="recordsDataTableActionButtons"
        label={t('Finish')}
        disabled={!localState.groupRecords?.length}
        onClick={onNext}
      />

      <Button id={elementIDs.back} type="button" className="p-button-outlined recordsDataTableActionButtons" label={t('Back')} onClick={onBack} />

      <Button
        id={elementIDs.cancel}
        type="button"
        className="p-button-outlined recordsDataTableActionButtons"
        label={t('Cancel')}
        onClick={() => history.goBack()}
      />
    </div>
  );
};

export default GroupCommentsActionButtons;
