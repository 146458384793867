import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidateGoupPicker } from '../hooks/useValidateGroupPicker';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { isSupplementary } from '../../../../../../config/specialitiesConfig';
import { inputs } from '../../../helpers/inputs';
import { steps } from '../../../helpers/steps';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';
import { setBatchFormToLocalStorage } from '../../../helpers/formToLocalStorage';

const GroupDxPickerActionButtons = () => {
  const history = useHistory();
  const { isDxValid, isCommentStepRequired, isReferralStepRequired } = useValidateGoupPicker();
  const { watch, getValues, localState, setLocalState } = useFormContext();
  const isRequiredComment = isCommentStepRequired();
  const isRequiredReferral = isReferralStepRequired();
  const showReview = !isRequiredReferral && !isRequiredComment;

  const onBack = () => {
    const speciality = watch(inputs.speciality.name);
    const invoiceType = watch(inputs.payor.name);
    const step = isSupplementary(speciality, invoiceType) ? steps.form : steps.groupServicesPicker; // CMO-2741 - Teleplan screen behavior changes for supplemental benefit practitioners
    setLocalState((prevState) => ({ ...prevState, step }));
  };

  const onReview = () => {
    if (isDxValid()) {
      // Go to preview step
      setLocalState((prevState) => {
        const updatedLocalState = { ...prevState, step: steps.groupPreview, prevStep: prevState.step };
        // Save the updated form state to the local storage
        setBatchFormToLocalStorage(getValues(), updatedLocalState);
        return updatedLocalState;
      });
    }
  };

  const onNext = () => {
    if (isDxValid()) {
      const speciality = watch(inputs.speciality.name);
      const invoiceType = watch(inputs.payor.name);
      const step = isSupplementary(speciality, invoiceType) ? steps.groupComments : steps.groupReferralPicker; // CMO-2741 - Teleplan screen behavior changes for supplemental benefit practitioners

      setLocalState((prevState) => {
        const updatedLocalState = { ...prevState, step, prevStep: prevState.step };
        // Save the updated form state to the local storage
        setBatchFormToLocalStorage(getValues(), updatedLocalState);
        return updatedLocalState;
      });
    }
  };

  return (
    <div className="flex gap-3">
      {showReview && (
        <Button
          id={elementIDs.review}
          type="button"
          className="recordsDataTableActionButtons"
          label={t('Finish')}
          disabled={!localState.groupRecords?.length}
          onClick={onReview}
        />
      )}

      <Button
        id={elementIDs.next}
        type="button"
        className={cx('recordsDataTableActionButtons', isRequiredComment || !showReview ? '' : 'p-button-outlined')}
        label={t('Next')}
        disabled={!localState.groupRecords?.length}
        onClick={onNext}
      />

      <Button id={elementIDs.back} type="button" className="p-button-outlined recordsDataTableActionButtons" label={t('Back')} onClick={onBack} />

      <Button
        id={elementIDs.cancel}
        type="button"
        className="p-button-outlined recordsDataTableActionButtons"
        label={t('Cancel')}
        onClick={() => history.goBack()}
      />
    </div>
  );
};

export default GroupDxPickerActionButtons;
