import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { getFromLocalStorage } from '../../../../utils/getFromLocalStorage';

// Set form state and local state to local storage
export const setBatchFormToLocalStorage = (formState, localState) => {
  const data = { formState, localState };
  localStorage.setItem(localStorageKeys.batchFormState, JSON.stringify(data));
};

// Get form state from local storage
export const getBatchFormFromLocalStorage = () => {
  return getFromLocalStorage(localStorageKeys.batchFormState);
};

export const resetBatchFormFromLocalStorage = () => {
  localStorage.removeItem(localStorageKeys.batchFormState);
};
